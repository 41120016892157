import "./hero.css";

const HeroTitle = () => {
  return (
    <div>
      <div id="cover" className="container_title-hero">
        <div className="overlay_title-hero"></div>
        <picture>
          <source media="(max-width: 600px)" srcset="/images/hero_small.jpg" />
          <source
            media="(max-width: 1200px)"
            srcset="/images/hero_medium.jpg"
          />
          <source srcset="/images/hero_big.jpg" />
          <img src="/images/hero_big.jpg" alt="Hero" />
        </picture>
        <div className="text_container_title-hero">
          <div className="breadcrumb__hero-title">
            <h4 top-title_hero>23 - 26 April 2025 | Lusaka, Zambia</h4>
          </div>
          <div className="main-title_hero-title">
            <h1>
              Africa Music
              <br />
              Conference
            </h1>
            <hr className="line_hero-title" />
          </div>
          <div className="subtitle_hero">
            <h4>
              Uniting Creatives and Innovators <br /> in Music & Arts Industry
            </h4>
          </div>
          <div className="button_title-hero">
            <a href="/ticketform" className="button">
              REGISTER NOW
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroTitle;
